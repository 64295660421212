import { signInWithRedirect, SignInWithRedirectInput } from "aws-amplify/auth";
import { KpButton } from "@components/elements/index";
import theme from "@styles/theme";
import { ButtonTypes } from "@utils/Constant";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";

const SocialLoginButtons = (isLogin: {isLogin: boolean}) => (
    <>
      <div style={{ textAlign: "center", paddingTop: "15px" }}>
        <KpButton
          id="signin-submit-google"
          onClick={() =>
            signInWithRedirect({ provider: "Google" } as SignInWithRedirectInput)
          }
          color={theme.brand.colors.darkGrey}
          buttonType={ButtonTypes.Secondary}
          fullWidth="mobile tablet computer"
          textColor={theme.brand.colors.black}
          type="submit"
        >
          <div style={{ width: "100%", paddingLeft: "15px" }}>
            <FcGoogle size={24} style={{ float: "left" }} />
            <span style={{ lineHeight: "24px" }}>{isLogin ? 'Login' : 'Sign up'} with Google</span>
          </div>
        </KpButton>
      </div>
      <div style={{ textAlign: "center", paddingTop: "15px" }}>
        <KpButton
          id="signin-submit-facebook"
          onClick={() =>
            signInWithRedirect({ provider: "Facebook" } as SignInWithRedirectInput)
          }
          color={theme.brand.colors.darkGrey}
          buttonType={ButtonTypes.Secondary}
          fullWidth="mobile tablet computer"
          type="submit"
        >
          <div style={{ width: "100%", paddingLeft: "15px" }}>
            <FaFacebookF size={24} style={{ float: "left", color: "#1877f2" }} />
            <span style={{ lineHeight: "24px" }}>{isLogin ? 'Login' : 'Sign up'} with Facebook</span>
          </div>
        </KpButton>
      </div>
    </>
  );

  export default SocialLoginButtons;